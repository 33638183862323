import React, { Component } from 'react';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/400-italic.css";// Defaults to weight 400
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700-italic.css"; 
import timeicon from './media/timeicon.png';
import aiicon from './media/aiicon.png';
import tailoredicon from './media/tailoredicon.png';
import arrowcontinue from './media/ai.png';
import aiimage from './media/aiimage.png';
import i18n from "i18next";

export class Home extends Component {


    constructor(props){
        super(props);
  
    }


    showCreateCourse = () => {
        this.props.create();
    }
    

    render() {
        return (
            <div>
                 <br />
        <div style={{textAlign: 'center', margin: '10px'}}><span style={{color: 'black', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('the.course')} </span><span style={{color: '#3815E8', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('tailored')} </span><span style={{color: 'black', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('for.you')} <br/>{i18n.t('thanks.to')} </span><span style={{color: '#3815E8', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('ai')}</span></div>
        <br />
        
        
      <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 50}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('save.time')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('save.time.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={timeicon} width="70px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 40}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('tailor.made')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('tailor.made.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={tailoredicon} width="70px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 40}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('smart')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('smart.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={aiicon} width="70px" />
    </div>
</div>

<div style={{width: '200px', height: '200px', position: 'relative', margin: '15px' }}>
    <div style={{width: 200, height: 200, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 40}} />
    <div style={{width: 180, height: 25, left: 10, top: 90, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('chatinfo')}</div>
    <div style={{width: 180, height: 50, left: 10, top: 130, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('chatinfo.desc')}</div>
    <div style={{width: 70, height: 70, left: 65, top: 15, position: 'absolute'}}>
    <img src={arrowcontinue} width="70px" />
    </div>
</div>
</div>

<br />
<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1,
    margin: '30px'}}>
<div onClick={this.showCreateCourse} style={{width: '300px', height: '60px', position: 'relative', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, position: 'absolute', }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderRadius: 60.80}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('create.course')}</div>
    </div>
    </div>
    </div>

<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
    <div style={{width: '300px', height: '300px', position: 'relative'}}>
    <div style={{width: 300, height: 300, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 250, height: 40, left: 25, top: 30, position: 'absolute', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '800',  wordWrap: 'break-word'}}>{i18n.t('how.works')}</div>
    <div style={{width: 250, height: 80, left: 25, top: 78, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.desc')}</div>
    <div style={{width: 40, height: 40, left: 70, top: 150, position: 'absolute'}}>
    <img src={aiicon} width="150px" />
    </div>
   </div>

   <div style={{width: '300px', height: '300px', position: 'relative'}}>
   <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>1</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.1')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 250, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>2</div>
    <div style={{width: 230, height: 69, left: 20, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.2')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>3</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.3')}</div>
    </div>
   </div>

</div>

<br /><br /><br />

<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   <div style={{width: '300px', height: '300px', position: 'relative'}}>
    <div style={{width: 300, height: 300, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderTopLeftRadius: 40, borderBottomLeftRadius: 40}} />
    <div style={{width: 270, height: 100, left: 20, top: 30, position: 'absolute', color: 'white', fontSize: 24, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('create.slogan')}</div>
    <div style={{width: 270, height: 80, left: 20, top: 150, position: 'absolute', color: 'white', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('create.slogan.desc')}</div>
    <div onClick={this.showCreateCourse} style={{width: 250, height: 50, left: 50, top: 224, position: 'absolute', cursor: 'pointer' }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 60.80}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('create.course')}</div>
    </div>
    </div>

    <div style={{width: '300px', height: '300px', position: 'relative',}}>
    <div style={{width: 300, height: 300, left: 0, top: 0, position: 'absolute'}}>
    <img src={aiimage} width="300px" />
    </div>
    </div>


</div>

<br />
<br />
<br />
<br />

<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
    <div style={{width: '300px', height: '300px', position: 'relative'}}>
    <div style={{width: 300, height: 300, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 250, height: 40, left: 25, top: 30, position: 'absolute', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '800',  wordWrap: 'break-word'}}>{i18n.t('how.works.chat')}</div>
    <div style={{width: 250, height: 80, left: 25, top: 78, position: 'absolute', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.chat.desc')}</div>
    <div style={{width: 40, height: 40, left: 70, top: 150, position: 'absolute'}}>
    <img src={arrowcontinue} width="150px" />
    </div>
   </div>

   <div style={{width: '300px', height: '300px', position: 'relative'}}>
   <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>1</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.chat.1')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 250, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>2</div>
    <div style={{width: 230, height: 69, left: 20, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.chat.2')}</div>
    </div>

    <div style={{width: '300px', height: '100px', position: 'relative'}}>
    <div style={{width: 300, height: 100, left: 0, top: 0, position: 'absolute'}} />
    <div style={{width: 50, height: 50, left: 0, top: 0, position: 'absolute', textAlign: 'center', color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>3</div>
    <div style={{width: 230, height: 69, left: 50, top: 21, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('how.works.chat.3')}</div>
    </div>
   </div>

</div>

<br />
<br />

<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1,
    margin: '30px'}}>
<div onClick={this.showCreateCourse} style={{width: '300px', height: '60px', position: 'relative', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, position: 'absolute', }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderRadius: 60.80}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('create.course')}</div>
    </div>
    </div>
    </div>


<br />
<br />

            </div>
        )
    }
}

export default Home
