import React, { Component } from 'react';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/400-italic.css";// Defaults to weight 400
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700-italic.css"; 
import edit from './media/Edit.png';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading, SemipolarLoading, PointSpreadLoading } from 'react-loadingg';
import i18n from "i18next";

export class Create extends Component {

     messagesEndRef = React.createRef()

    constructor(props){
        super(props);
        this.state = { subscriptionPlan: props.plan, langs: props.langs, logged: props.logged, codeVerification: false, activationcode: '', 
        subject: '', comments: '', selectedLang: 0, level: 0, lenght: 0, continue: false, confirmMobileNumber: false, confirmMobileNumberSubscription: false, 
        prefixDisplay: '🇮🇹 +39', openPrefix: false, isLoading: false, mobileValue: '', prefixValue: '39',
         completedPopup: false, isLoginProcess: false, needSubscription: false };
    }

    scrollToBottom = () => {
        window.scrollTo({
            top: this.messagesEndRef.current.offsetTop,
            behavior: "smooth"
          })
        return;
        this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" });
      }

    insertText = (e) => {
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        if(fieldName == 'subject' && insert.length== 0){
            this.setState({ continue: false });
        } 
        this.setState({ [fieldName]: insert, channelError: false});     
    }

    confirmSubject = () => {
        if(this.state.subject.length > 0){
            this.setState({ continue: true });
        }      
    }

    selectLevel = (selectedLevel) => {
        if(this.state.subscriptionPlan == '0'){
            if(selectedLevel == '3'){
                if(!this.state.logged){
                    this.setState({ confirmMobileNumberSubscription: true, isLoginProcess: true });
                }else{
                    this.setState({ needSubscription: true, needSubscriptionDesc: i18n.t('you.need.plan') });
                }
                return;
            }
        }
        this.setState({ level: selectedLevel });
        this.scrollToBottom();
    }

    selectLang = (selectedLang) => {
        this.setState({ selectedLang: selectedLang });
        this.scrollToBottom();
    }
    
    selectLenght = (selectedLenght) => {
        if(this.state.subscriptionPlan == '0'){
            if(selectedLenght != '1'){
                if(!this.state.logged){
                    if(!this.state.logged){
                        this.setState({ confirmMobileNumberSubscription: true, isLoginProcess: true });
                    }
                }else{
                    this.setState({ needSubscription: true, needSubscriptionDesc: i18n.t('you.need.plan') });
                }
                return;
            }
        }else if(this.state.subscriptionPlan == '1'){
            if(selectedLenght == '3'){
                if(!this.state.logged){
                    if(!this.state.logged){
                        this.setState({ confirmMobileNumberSubscription: true, isLoginProcess: true });
                    }
                }else{
                    //buy popup
                }
                return;
            }
        }
        this.setState({ lenght: selectedLenght });
        this.scrollToBottom();
    }


    confirmCourse = () => {
        if(this.state.logged){
            //confirm alert
            this.generateCourse();
        }else{
            this.setState({ confirmMobileNumber: true, isLoginProcess: false });
        }
       
    }

    toggle = () => {
        this.setState({ confirmMobileNumber: false, confirmMobileNumberSubscription: false });
    }

    selectPrefix = (e) => {
        
        this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id, openPrefix: false });
    
    }

    closeAnimation = (e) => {
        this.setState({ animationShake1: '', animationShake2: ''}); 
        clearInterval(this.intervalID);
    }

    confirmMobileNumberAction = () => {
       
       

        if(!(this.state.mobileValue.length>8 && this.state.mobileValue.length<12)){
            return;
        }

        if(!this.state.ck2){
            this.setState({ animationShake2: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }

        if(!this.state.ck1){
            this.setState({ animationShake1: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }
      
        if(this.state.isLoading){
            return;
        }
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              mobile: this.state.mobileValue,
              prefix: this.state.prefixValue
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/login';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
           if(r.status == 'code'){
            this.setState({ codeVerification: true, confirmMobileNumber: false, confirmMobileNumberSubscription: false, isLoading: false });
           }else{
            localStorage.removeItem('session_token');
              window.location.reload();
           }
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    confirmActivationCode = () => {
        if(this.state.activationcode.length!=4){
            return;
        }

        if(this.state.isLoading){
            return;
        }

        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.activationcode,
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
           
           if(r.status == 'ok'){
            this.setState({ codeVerification: false, confirmMobileNumber: false, confirmMobileNumberSubscription: false  });
            
            if(this.state.isLoginProcess){
                
                this.setState({ logged: r.logged, subscriptionPlan: r.plan });
            }else{
                this.generateCourse();
            }
           }else{
            this.setState({ activationcode: '' });
           }

           this.setState({ isLoading: false, isLoginProcess: false });
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    generateCourse = () => {   

        this.setState({ completedPopup: true, confirmMobileNumber: false });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              subject: this.state.subject,
              level: this.state.level,
              lenght: this.state.lenght,
              comments: this.state.comments,
              lang: this.state.selectedLang
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/course/generate';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
           
            if(r.code == 'error'){
                this.setState({ completedPopup: false, needSubscription: true, needSubscriptionDesc: i18n.t('you.need.plan.number.course') });
            }else{
                setTimeout(() => { 
                    this.checkGenerationStatus(r.code); 
                }, 2000); 
            }
            
          }).catch(e => {
              localStorage.removeItem('session_token');
              window.location.reload();
          });
    }

    checkGenerationStatus = (code) => {
    var sessiontoken = localStorage.getItem('session_token');
      
    if(sessiontoken === null){
        window.location.reload();
    }
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({
          token: sessiontoken,
          code: code
        })
        };
      
        const apiUrl = 'https://teachyai.com/api/client/v1/course/';
  
      fetch(apiUrl, requestOptions)
      .then(r => r.json())
      .then(r => {
        
        if(r.course.info.created == '0'){
           
            setTimeout(() => { 
                this.checkGenerationStatus(code); 
            }, 2000); 
        }else{
           
            var location =  window.location.href+code;
            window.location.href = location;
        }
      }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
      });
    }


    showPrefixSelection = () => {
        this.setState({ openPrefix: true });
    }


    checkboxAction = (e) => {
        var checkBox = e.target.name;
        if(checkBox === "ck1"){
            if(this.state.ck1){
                this.setState({ ck1: false}); 
            }else{
                this.setState({ ck1: true}); 
            }
        }else if(checkBox === "ck2"){
            if(this.state.ck2){
                this.setState({ ck2: false}); 
            }else{
                this.setState({ ck2: true}); 
            }
        }
        
    }

    cancelPlans = () => {
        this.setState({ needSubscription: false });
    }

    goToPlans = () => {
        this.setState({ needSubscription: false });
        this.props.goToPlans();
    }


    render() {
        return (
            <div> <div style={{textAlign: 'center', margin: '10px'}}>
            <span style={{color: 'black', fontSize: 30, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('describe.briefly')}<br /> {i18n.t('the.topic')} </span><span style={{color: '#3815E8', fontSize: 30, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('topic')}</span><span style={{color: 'black', fontSize: 30, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}> {i18n.t('of.course')}</span></div>
        
       
        <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
        <div style={{textAlign: 'center', maxWidth: '650px', margin: '10px'}}><span style={{color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('course.topic')}</span><br/><br /><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('course.topic.desc.1')} '<strong>{i18n.t('course.topic.word.1')}</strong>'. <br/> {i18n.t('course.topic.desc.2')} '<strong>{i18n.t('course.topic.word.2')}</strong>'.</span></div>
        </div>
        <br />

<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   
   <div style={{width: '300px', height: '60px', position: 'relative'}}>
    <div style={{width: 300, height: 50, left: 0, top: 10, position: 'absolute'}} />
    <div style={{width: 290, height: 50, left: 5, top: 0, position: 'absolute'}}>
        <div style={{width: 290, height: 50, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
               
                <input 
                style={{ paddingLeft: '35px', border: 'none', color: 'rgb(50,50,50)', height: '50px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgba(245,245,0,0)', outlineWidth: '0',fontFamily: 'Plus Jakarta Sans', fontWeight: '500', position: 'relative'}} type="text"  
                name="subject"
                autoComplete="off"
                autoCorrect="false"
                value={this.state.subject}
                placeholder={i18n.t('topic.example')}
                onChange={this.insertText}
                />
                
        <div style={{width: 12, height: 12, left: 15, top: 17, position: 'absolute'}}>
        <img src={edit} width="12px" />
        </div>
    </div>
</div>

<div style={{width: '200px', height: '60px', position: 'relative'}}>
    <div style={{width: 200, height: 50, left: 0, top: 10, position: 'absolute'}} />
    <div onClick={this.confirmSubject} style={{width: 135, height: 50, left: 30, top: 0, position: 'absolute', cursor: 'pointer'}}>
        <div style={{width: 140, height: 50, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderRadius: 100}} />
        <div style={{width: 140, height: 20, left: 0, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('continue')}</div>
    </div>
</div>


</div>

<br />

{this.state.continue == true &&
<div>
<div style={{textAlign: 'center', margin: '10px'}}>
    <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
    {i18n.t('select.languages')}</span></div>
        <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   
  

            {
           this.state.langs.map((lang, i) =>
           <div key={i}  onClick={() =>this.selectLang(lang.id)} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
           <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: this.state.selectedLang == lang.id ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
           <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{lang.display_text}</div>
           </div>
                )
                }


</div> 
</div>  
}  

{(this.state.continue == true && this.state.selectedLang!=0) &&
<div>
<div style={{textAlign: 'center', margin: '10px'}}>
    <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
    {i18n.t('level')}</span></div>
        <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   
   <div onClick={() =>this.selectLevel('1')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: this.state.level == 1 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('basic')}</div>
    </div>

    <div onClick={() =>this.selectLevel('2')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: this.state.level == 2 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('medium')}</div>
    </div>

    
    <div onClick={() =>this.selectLevel('3')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background:  this.state.subscriptionPlan == 0 ? 'rgb(240,240,240)' : 'white', borderRadius: 100, border: this.state.level == 3 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: this.state.subscriptionPlan == 0 ? 'rgb(200,200,200)' : 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('advanced')}</div>
    </div>
   


</div> 
</div>  
}  

<br />

{(this.state.continue == true && this.state.selectedLang!=0 && this.state.level>0) &&
<div>
<div style={{textAlign: 'center', margin: '10px'}}>
    <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
    {i18n.t('length')}</span></div>
        <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   
   <div onClick={() =>this.selectLenght('1')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: this.state.lenght == 1 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('short')}</div>
    </div>

    <div onClick={() =>this.selectLenght('2')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: this.state.subscriptionPlan == 0 ? 'rgb(240,240,240)' : 'white', borderRadius: 100, border: this.state.lenght == 2 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color: this.state.subscriptionPlan == 0 ? 'rgb(200,200,200)' : 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('medium.length')}</div>
    </div>

    <div onClick={() =>this.selectLenght('3')} style={{width: '100px', height: '40px', position: 'relative', margin: '10px', cursor: 'pointer'}}>
    <div style={{width: 100, height: 40, left: 0, top: 0, position: 'absolute', background: this.state.subscriptionPlan == 2 ? 'white' : 'rgb(240,240,240)', borderRadius: 100, border: this.state.lenght == 3 ? '1px #3815E8 solid' : '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
    <div style={{ width: 100, height: 20, left: 1, top: 12, position: 'absolute', textAlign: 'center', color:  this.state.subscriptionPlan == 2 ? 'black' : 'rgb(200,200,200)', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '300', wordWrap: 'break-word'}}>{i18n.t('long')}</div>
    </div>


    </div>  
    </div>  
    } 

<br />

{(this.state.continue == true && this.state.selectedLang!=0 && this.state.level>0 && this.state.lenght>0) &&
<div>
<div style={{textAlign: 'center', margin: '10px'}}>
    <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
    {i18n.t('specifications')}</span></div>
<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
   
   <div style={{width: '300px', height: '60px', position: 'relative'}}>
    <div style={{width: 300, height: 50, left: 0, top: 10, position: 'absolute'}} />
    <div style={{width: 290, height: 50, left: 5, top: 0, position: 'absolute'}}>
        <div style={{width: 290, height: 50, left: 0, top: 0, position: 'absolute', background: 'white', borderRadius: 100, border: '1px rgba(9.11, 5.43, 27.77, 0.10) solid'}} />
               
                <input 
                style={{ paddingLeft: '35px', border: 'none', color: 'rgb(50,50,50)', height: '50px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgba(245,245,0,0)', outlineWidth: '0',fontFamily: 'Plus Jakarta Sans', fontWeight: '500', position: 'relative'}} type="text"  
                name="comments"
                autoComplete="off"
                autoCorrect="false"
                value={this.state.comments}
                placeholder={i18n.t('specifications.desc')}
                onChange={this.insertText}
                />
                
        <div style={{width: 12, height: 12, left: 15, top: 17, position: 'absolute'}}>
        <img src={edit} width="12px" />
        </div>
    </div>
</div>

</div>


<br />
<br />
<div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
<div style={{width: '200px', height: '60px', position: 'relative'}}>
    <div style={{width: 200, height: 50, left: 0, top: 10, position: 'absolute'}} />
    <div onClick={this.confirmCourse} style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', cursor: 'pointer'}}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderRadius: 100}} />
        <div style={{width: 200, height: 20, left: 0, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('generate.course')}</div>
    </div>
</div>
</div>
</div>
}


<div style={{height: '200px'}} ref={this.messagesEndRef} />

            <Drawer
                    open={this.state.needSubscription}
                    onRequestClose={this.cancelPlans}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('premium.function.desc')}</span></div>
            <div style={{ textAlign: 'center', marginLeft: '20px', marginRight: '20px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{this.state.needSubscriptionDesc}</span></div>
   
            
            <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.goToPlans}
            >{i18n.t('go.to.plans')}</p>
                    </div>
                    <div onClick={this.cancelPlans} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel')}</span></div>
   
           
                    </div>
                    </Drawer>

            <Drawer
                    open={this.state.completedPopup}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('generating')}</span><br /><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('the.chapters')}</span></div>
            
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <BoxLoading size="large" color="#3815E8" />
            </div>
         
   
            <div style={{ position: 'absolute', textAlign: 'center', bottom: '15px', marginLeft: '20px', marginRight: '20px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('please.wait')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.confirmMobileNumber}
                        onRequestClose={this.toggle}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}></span><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('almost.there')}</span></div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '15px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('generation.time')}<br />{i18n.t('insert.your.number')}</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#3815E8', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'bold'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '20px', border: 'none', color: 'black', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(233,240,253)', outlineWidth: '0', fontSize: 14}} type="tel"  
                name="mobileValue"
                autoComplete="off"
                value={this.state.mobileValue}
                placeholder={i18n.t('mobile.phone')}
                onChange={this.insertText}
                />
                </div>
                </div>
                </div>


                 <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/terms-and-conditions">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/privacy-policy">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>
                          
                    <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmMobileNumberAction}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.generateCourse} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('skip.login')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.confirmMobileNumberSubscription}
                        onRequestClose={this.toggle}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}></span><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('premium.function')}</span></div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '15px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('premium.function.desc')}<br />{i18n.t('insert.number')}</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#3815E8', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'bold'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '20px', border: 'none', color: 'black', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(233,240,253)', outlineWidth: '0', fontSize: 14}} type="tel"  
                name="mobileValue"
                autoComplete="off"
                value={this.state.mobileValue}
                placeholder={i18n.t('mobile.phone')}
                onChange={this.insertText}
                />
                </div>
                </div>
                </div>


                 <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/terms-and-conditions">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/privacy-policy">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>
                          
                    <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmMobileNumberAction}
            >{i18n.t('confirm')}</p>
                    </div>

                    <div onClick={this.toggle} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel')}</span></div>
   
                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.openPrefix}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>

                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.codeVerification}
                        modalElementClass="modalMobileCode">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: '#3815E8', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('confirm.your.number')} </span>
            <br /> <br /><span style={{color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>+{this.state.prefixValue} {this.state.mobileValue}</span>
            </div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '10px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('insert.activation.code')}</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', fontWeight: 'bold', color: 'black', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(240,240,240)', outlineWidth: '0', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="tel" 
                name="activationcode"
                autoComplete="off"
                maxLength={4}
                value={this.state.activationcode}
                placeholder={i18n.t('activation.code')}
                onChange={this.insertText}
                />
               </div>

                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmActivationCode}
            >{i18n.t('confirm')}</p>
                    </div>
                    </div>
                    </Drawer>

                   
            </div>
        )
    }
}

export default Create
