import './App.css';
import { Component } from 'react';
import Home from './Home';
import Create from './Create';
import Course from './Course';
import i18n from "i18next";
import MyCourses from './MyCourses';
import userprofile from './media/userprofile.png';
import SubscriptionPlan from './SubscriptionPlan';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Drawer from 'react-drag-drawer';
import aiimage from './media/ai.png';
import './Popupstyle.css';

class App extends Component {
  
  constructor(props){
    super(props);
    
   
    var courseCode = window.location.href.replace(window.location.origin, "");
    courseCode = courseCode.replace("/", "");   
  
    var subError = false;
    var subOk = false;
    if(courseCode == 'error'){
      courseCode = '';
      subError = true;
    }else if(courseCode == 'success'){
      courseCode = '';
      subOk = true;
    }
    
    this.state = {showCreate: false, logged: false, showHome: false, showMyCourses: false,
      courseCode: courseCode, showCourse: false, courseInfo: null, langs: [], isReady: false, subscriptionPlan: 0,
    showSubscriptionInfo: false, currentSection: 0, subError: subError, subOk: subOk };
        
  }

  componentDidMount () {
    
      var sessiontoken = localStorage.getItem('session_token');
     
      if(sessiontoken === null){
        this.createToken();
        return;
      }else{
        this.checkToken(sessiontoken);
        return;
      }
  }

  

  showCreateCourse = () => {
    this.setState({ currentSection: 0, showCreate: true, showHome: false, showCourse: false, showMyCourses: false, showSubscriptionInfo: false, isReady: true });
  }

  showHome = () => {
   if(this.state.courseCode != ''){
    window.location.href = window.location.origin;
    return;
   }
    this.setState({ currentSection: 0, showCreate: false, showHome: true, showCourse:false, showMyCourses: false, showSubscriptionInfo: false, isReady: true });
  }

  showMyCourses = () => {
    if(this.state.showMyCourses){
      this.showHome();
    }else{
      this.setState({ currentSection: 1, showCreate: false, showHome: false, showCourse:false, showMyCourses: true, showSubscriptionInfo: false, isReady: true });
    }
    
  }

  showSubscriptionPage = () => {
    this.setState({ currentSection: 2, showCreate: false, showHome: false, showCourse:false, showMyCourses: false, showSubscriptionInfo: true, isReady: true });
    
  }

  firstLoad = () => {
    if(this.state.courseCode == ''){
      this.showHome();
    }else{
      this.getCourse(this.state.courseCode);
    }
  }

  getCourse = (code) => {
    
    var sessiontoken = localStorage.getItem('session_token');
      
    if(sessiontoken === null){
        window.location.reload();
    }
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({
          token: sessiontoken,
          code: code
        })
        };
      
        const apiUrl = 'https://teachyai.com/api/client/v1/course/';
  
      fetch(apiUrl, requestOptions)
      .then(r => r.json())
      .then(r => {
      
        this.setState({ courseInfo: r.course, showCreate: false, showHome: false, showCourse: true, showMyCourses: false, showSubscriptionInfo: false, isReady: true });
        if(r.course.info.created != '2'){
          setTimeout(() => { 
              this.getCourse(code); 
          }, 3000); 
        }
      }).catch(e => {
        this.showHome();
      });
  }

  checkToken = (token) => {
  
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: token
      })
      };
    
      const apiUrl = 'https://teachyai.com/api/client/v1/hello/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      this.setState({ logged: r.logged, langs: r.langs, isReady: true, subscriptionPlan: r.plan });
      this.firstLoad();
      
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  createToken = () => {
  
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      
      };
    
      const apiUrl = 'https://teachyai.com/api/client/v1/hello/client';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      localStorage.setItem('session_token', r.token);
      this.checkToken(r.token);
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  logoutNow = () => {

   
    var sessiontoken = localStorage.getItem('session_token');
      
    if(sessiontoken === null){
        window.location.reload();
    }
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({
          token: sessiontoken
        })
        };
      
        const apiUrl = 'https://teachyai.com/api/client/v1/hello/logout';
  
      fetch(apiUrl, requestOptions)
      .then(r => r.json())
      .then(r => {
      
        if(r.status == 'ok'){
          localStorage.removeItem('session_token');
        }
        window.location.reload();
      }).catch(e => {
        window.location.reload();
      });
  }

  loggedAction = (status) => {
    this.setState({ logged: status });
  }

  changeSection = (event) => {
    if(event.value == '0'){
      this.showHome();
    }else if(event.value == '1'){
      this.showMyCourses();
    }else if(event.value == '2'){
      this.showSubscriptionPage();
    }else if(event.value == '3'){
      this.logoutNow();
    }
  }

  clickOnMyProfile = () => {
    if(!this.state.logged){
      this.showMyCourses();
    }
  }

  closePopups = () => {
    window.location.href = '/';
    this.setState({ subError: false, subOk: false });
  }

  render(){
    
    return (
     
    <div className='App' style={{ backgroundColor: 'white'}}>
   <div style={{ top: 0, width: '100%', height: '50px', position: 'sticky', backgroundColor: 'white', zIndex: 1000 }}>
   <div style={{ width: '100%', top: '49px', height: '1px', backgroundColor: '#F2F1F9', position: 'absolute' }}></div>
  

  
        <div onClick={this.clickOnMyProfile} style={{ display: "flex",position: 'absolute', right: '1px' }}>
        <div  style={{textAlign: 'center', margin: '8px', cursor: 'pointer', width: '150px'}}>
         
          <span  style={{ color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>
          {!this.state.logged &&
          <span>Login</span> }
          {this.state.logged &&
          <Dropdown options={[{ value: '0', label: 'Home' },{ value: '1', label: i18n.t('my.courses') },{ value: '2', label: i18n.t('subscription') },{ value: '3', label: i18n.t('logout') }]} onChange={this.changeSection}  placeholder="Home" />
          }
          </span></div>
        </div>
        

   <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
        <div onClick={this.showHome} style={{textAlign: 'center', margin: '12px', cursor: 'pointer'}}>
          <span  style={{ color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>
            Teachy AI</span></div>
        </div>
       
   </div>
     
   { (this.state.isReady && this.state.showHome) &&
       <Home create={this.showCreateCourse} />
   } 

{ (this.state.isReady && this.state.showCreate) && 
      <Create goToPlans={this.showSubscriptionPage} plan={this.state.subscriptionPlan} langs={this.state.langs} logged={this.state.logged} />
   }  

  { (this.state.isReady && this.state.showMyCourses) && 
      <MyCourses plan={this.state.subscriptionPlan} create={this.showCreateCourse} loggedAction={this.loggedAction} logged={this.state.logged} close={this.showHome} />
   }  

   {(this.state.isReady && this.state.courseInfo != null && this.state.showCourse) &&
   <Course loggedAction={this.loggedAction} logged={this.state.logged} goToPlans={this.showSubscriptionPage} plan={this.state.subscriptionPlan} info={this.state.courseInfo} />
   }
{ (this.state.isReady && this.state.showSubscriptionInfo) && 
   <SubscriptionPlan />
}


<Drawer
                    open={this.state.subError}
                    onRequestClose={this.closePopups}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{marginTop: '25px', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('payment.ko')}</span></div>
            <div style={{marginTop: '40px', textAlign: 'center'}}><span style={{color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('payment.ko.desc')}</span></div>
            
           
           
                    <div onClick={this.closePopups} style={{  marginTop: '40px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
           
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.subOk}
                    onRequestClose={this.closePopups}
                        modalElementClass="modal">
                            <div>
                             <div style={{ margin: '20px', textAlign: 'center'}}><span style={{color: 'black', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('payment.ok.desc')}</span></div>
            
            <div style={{alignItems: 'center', marginTop: '30px', display: 'flex', justifyContent: 'center'}}>
            <img src={aiimage} width="70px" />
            </div>
          
                    <div onClick={this.closePopups} style={{  marginTop: '20px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
           
                    </div>
                    </Drawer>
    </div>
  );
    }
}

export default App;
