import React, { Component } from 'react';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/400-italic.css";// Defaults to weight 400
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700-italic.css"; 
import { BoxLoading, SemipolarLoading, PointSpreadLoading } from 'react-loadingg';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import './Chat.css';
import download from './media/downloadicon.png';
import share from './media/shareicon.png';
import chaticonwhite from './media/chaticonwhite.png';
import i18n from "i18next";
import sendmsg from './media/sendmsg.png';
import close from './media/close.png';

export class Course extends Component {


    constructor(props){
        super(props);

        var logged = props.logged;
       

        this.state = { plan: props.plan, course: props.info, showAIBot: false, 
            chatHistory: [], subject: '', isLoadingChat: false, needSubscription: false, activationcode: '', 
            codeVerification: false, prefixDisplay: '🇮🇹 +39', openPrefix: false, 
            mobileValue: '', prefixValue: '39', confirmMobileNumber: !logged, isLogged: logged, linkIsCopied: false };
    }

    static getDerivedStateFromProps(props, current_state) {
        
        if(current_state.course !== props.info){
            return {
                course: props.info,
              }
        }
        
        return null;
    }

    closeChatBot = () => {
        this.setState({ showAIBot: false });
    }

    showBot = () => {
        this.setState({ showAIBot: true });
    }

    insertText = (e) => {
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert });     
    }

    shareLink = () => {
        var url = 'https://teachyai.com/'+this.state.course.info.course_code;
        navigator.clipboard.writeText(url);
        this.setState({ linkIsCopied: true });
        setTimeout(() => { 
           this.hideLinkCopiedStatus();
        }, 1500); 
    }

    hideLinkCopiedStatus = () => {
        this.setState({ linkIsCopied: false });
    }

    downloadPdfCourse = () => {
        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.course.info.course_code
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/course/pdf';
      
          fetch(apiUrl, requestOptions)
          .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', this.state.course.info.course_subject+'.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });
        })
    }

    cancelPlans = () => {
        this.setState({ needSubscription: false });
    }

    goToPlans = () => {
        this.setState({ needSubscription: false });
        this.props.goToPlans();
    }

    sendChatMessage = () => {
        var new_message = this.state.subject;
        if(new_message == '' || this.state.isLoadingChat){
            return;
        }

        if(this.state.plan == '0'){
            this.setState({ needSubscription: true });
            return;
        }


        this.setState({ isLoadingChat: true });

        var chat = this.state.chatHistory;
        var first_chat = [];
        first_chat.message_text = new_message;
        first_chat.from_who = 'me';
        chat.push(first_chat);

        this.setState({ chatHistory: chat, subject: '' });
        

        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken == null){
         
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.course.info.course_code,
              message: new_message
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/course/chat';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
           
            var chat = this.state.chatHistory;
            var first_chat = [];
            first_chat.message_text = r.response;
            first_chat.from_who = 'bot';
            chat.push(first_chat);
            this.setState({ chatHistory: chat, isLoadingChat: false });

          }).catch(e => {
            this.setState({ isLoadingChat: false });
          });
        
    }


    toggle = () => {
        this.props.close();
    }

    closeLogin = () => {
        this.setState({ confirmMobileNumber: false });
    }

    selectPrefix = (e) => {
        
        this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id, openPrefix: false });
    
    }

    showPrefixSelection = () => {
        this.setState({ openPrefix: true });
    }

    closeAnimation = (e) => {
        this.setState({ animationShake1: '', animationShake2: ''}); 
        clearInterval(this.intervalID);
    }

    confirmNumber = () => {
        if(!(this.state.mobileValue.length>8 && this.state.mobileValue.length<12)){
            return;
        }

        if(!this.state.ck2){
            this.setState({ animationShake2: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }

        if(!this.state.ck1){
            this.setState({ animationShake1: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }
      
        if(this.state.isLoading){
            return;
        }
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              mobile: this.state.mobileValue,
              prefix: this.state.prefixValue
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/login';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
           if(r.status == 'code'){
            this.setState({ codeVerification: true, confirmMobileNumber: false, isLoading: false });
           }else{
            localStorage.removeItem('session_token');
              window.location.reload();
           }
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    confirmActivationCode = () => {
        if(this.state.activationcode.length!=4){
            return;
        }

        if(this.state.isLoading){
            return;
        }

        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.activationcode,
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
           if(r.status == 'ok'){
            window.location.reload();
            
           }else{
            this.setState({ activationcode: '' });
           }

           this.setState({ isLoading: false });
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    showCreateCourse = () => {
        this.props.create();
    }

    checkboxAction = (e) => {
        var checkBox = e.target.name;
        if(checkBox === "ck1"){
            if(this.state.ck1){
                this.setState({ ck1: false}); 
            }else{
                this.setState({ ck1: true}); 
            }
        }else if(checkBox === "ck2"){
            if(this.state.ck2){
                this.setState({ ck2: false}); 
            }else{
                this.setState({ ck2: true}); 
            }
        }
        
    }

    render() {
        return (
        <div> 
       
            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: '#3815E8', fontSize: 32, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
            {this.state.course.info.course_subject}
            </span>
            </div>

            <div style={{textAlign: 'center', margin: '10px'}}>
            <span style={{color: 'rgb(200,200,200)', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>
             {this.state.course.info.course_level == "1" ? i18n.t('basic.level') : this.state.course.info.course_level == "2" ? i18n.t('medium.level') : i18n.t('advanced.level')}  | {this.state.course.chapters.length} {i18n.t('chapters')} | {this.state.course.info.display_text}
            </span>
            </div>

            <div style={{textAlign: 'center', marginTop: '20px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>         
            <div onClick={this.downloadPdfCourse} style={{ width: '25px', cursor: 'pointer' }}> <img style={{ marginLeft: '0px' }} src={download} width="22px" /></div>          
            <div onClick={this.shareLink} style={{ width: '25px', cursor: 'pointer' }}> <img style={{ marginLeft: '10px' }} src={share} width="22px" /></div>               
            {this.state.linkIsCopied && <div style={{ marginLeft: '10px', fontSize: '10px' }}> {i18n.t('link.copied')}</div>  }        
            </div>
            
           
            <div style={{ width: '100%', marginTop: '20px', marginBottom: '5px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
            <div style={{textAlign: 'center', marginTop: '0px', marginBottom: '20px'}}>
            <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
            {i18n.t('summary')}
            </span>
            </div>

            {
           this.state.course.chapters.map((chapter, i) =>
 
           <div key={i} style={{textAlign: 'left', marginLeft: '30px', marginRight: '30px'}}>
           <div style={{textAlign: 'left'}}>
           <span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
           {i+1}) {chapter.title}
           </span>
           </div>
           { chapter.paragraphs.map((paragraph, k) =>
                                        
            <div key={1000+k}>
           <div style={{textAlign: 'left', margin: '10px'}}>
           <span style={{color: 'rgb(60,60,60)', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
           {i+1}.{k+1}) {paragraph.title}
           </span>
           </div>
           </div>
            )
                
            }
           <br />
           </div>
                
                )

            
            }


   <div style={{ width: '100%', marginTop: '0px', marginBottom: '20px', height: '1px', backgroundColor: '#F2F1F9' }}></div>

      
            <br /><br />

            

            {
           this.state.course.chapters.map((chapter, i) =>
 
           <div key={i}>
            <div  style={{textAlign: 'left', marginLeft: '30px', marginRight: '30px'}}>
           <div style={{textAlign: 'left'}}>
           <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
           {i+1}) {chapter.title}
           </span>
           </div>
           { chapter.paragraphs.map((paragraph, k) =>
                                        
            <div key={1000+k}>
                <br />
           <div style={{textAlign: 'left', margin: '20px'}}>
           <span style={{color: 'rgb(60,60,60)', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>
           {i+1}.{k+1}) {paragraph.title}
           </span>
           </div>
           <div style={{textAlign: 'left',  display: 'inline', alignItems: 'center'}}>
          
           <span style={{ clear: 'left', textRendering: 'optimizeLegibility', lineHeight: '24px', textShadow: '0 0 1px transparent', color: 'rgb(60,60,60)', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>
           {paragraph.image != '' && <div style={{ marginRight: '15px', marginTop: '5px', float: 'left', display: 'inline-block', verticalAlign: 'top'}}><img src={paragraph.image} /></div>}
            {paragraph.content}
           </span>
           <br />
           </div>
           <br />
           </div>
            )
                
            }
            
           
           </div>

   <div style={{ width: '100%', marginTop: '30px', marginBottom: '30px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
           </div>
                
                )

            
            }

            {!this.state.showAIBot &&
            <div onClick={this.showBot} style={{ position: 'fixed', bottom: '10px', right: '10px', width: '50px', height: '50px',
             backgroundColor: '#3815E8', borderRadius: '25px', cursor: 'pointer' }}>
                <img style={{ margin: '12.5px' }} src={chaticonwhite} width="25px" />
            </div>
            }

            {this.state.showAIBot &&
            <div style={{ position: 'fixed', bottom: '10px', right: '10px', width: '280px', height: '450px',
             backgroundColor: 'white', border: '2px solid rgb(245,245,245)', borderRadius: '15px', backgroundColor: 'rgb(245,245,245)' }}>
               <div style={{ textAlign: 'center', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', 
                width: '100%', height: '30px', backgroundColor: 'rgb(245,245,245)', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                <div onClick={this.closeChatBot} style={{ left: '10px', position: 'absolute', cursor: 'pointer' }}> 
                <img style={{ }} src={close} width="20px" />
                </div>
                <div style={{ marginTop: '10px'}}> AI Chat Bot</div>
                
                </div>

                <div style={{  width: '100%', height: '355px', backgroundColor: 'white', overflowX: 'hidden' }}>
                <div className="imessage">
                
                <p className="from-them">{i18n.t('chat.welcome')}</p> 

                { this.state.chatHistory.map((chat, k) =>
                chat.from_who == 'bot' ?
                <p  key={10000+k} className="from-them">{chat.message_text}</p> 
                  :
                <p  key={10000+k} className="from-me">{chat.message_text}</p>                     
                 
                )
                                            
                }
                {this.state.isLoadingChat &&
                 <p className="from-neutral"><PointSpreadLoading size="small" color="#3815E8" /></p> 
                }
                

                </div>
                </div>

                <div style={{ width: '100%', height: '55px', backgroundColor: 'white', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
               
                <div style={{width: '100%', height: '55px', position: 'relative'}}>
    <div style={{width: 280, height: 50, left: 0, top: 10, position: 'absolute'}} />
    <div style={{width: 270, height: 50, left: 5, top: 0, position: 'absolute'}}>
        <div style={{width: 270, height: 50, left: 0, top: 0, position: 'absolute', background: 'white', 
        borderRadius: 10, border: '1px solid rgb(245,245,245)'}} />
               
                <input 
                style={{ paddingLeft: '5px', paddingRight: '60px', border: 'none', color: 'rgb(50,50,50)', height: '50px', width: '225px', borderRadius: '5px', 
                backgroundColor: 'rgba(245,245,0,0)', fontSize: '12px', outlineWidth: '0',fontFamily: 'Plus Jakarta Sans', fontWeight: '500', position: 'relative'}} type="text"  
                name="subject"
                autoComplete="off"
                autoCorrect="false"
                value={this.state.subject}
                placeholder={i18n.t('chat.example')}
                onChange={this.insertText}
                />
                
        <div onClick={this.sendChatMessage} style={{width: 30, height: 30, right: 5, top: 12, position: 'absolute', backgroundColor:'#3815E8', borderRadius: '15px'}}>
        <img style={{ margin: '7.5px' }}  src={sendmsg} width="15px" />
        </div>
    </div>
</div>
                </div>
            </div>
            }


<Drawer
                    open={this.state.needSubscription}
                    onRequestClose={this.cancelPlans}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('premium.function.desc')}</span></div>
            <div style={{ textAlign: 'center', marginLeft: '20px', marginRight: '20px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('you.need.plan.chatbot')}</span></div>
   
            
            <div  style={{marginTop: '15px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.goToPlans}
            >{i18n.t('go.to.plans')}</p>
                    </div>
                    <div onClick={this.cancelPlans} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel')}</span></div>
   
           
                    </div>
                    </Drawer>

            <Drawer
                    open={this.state.course.info.created == '2' ? false : true}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('generating')}</span><br /><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('paragraphs')}</span></div>
            
            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <BoxLoading size="large" color="#3815E8" />
            </div>
         
   
            <div style={{ position: 'absolute', textAlign: 'center', bottom: '25px', marginLeft: '20px', marginRight: '20px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('wait.minutes')}</span></div>
   
                    </div>
                    </Drawer>
        

                    <Drawer
                    open={this.state.confirmMobileNumber}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>Accedi ai tuoi </span><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>corsi</span></div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '15px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>Conferma il tuo numero di telefono per accedere ai <span style={{color: '#3815E8', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>tuoi corsi</span> e consultarli da questo dispositivo</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#3815E8', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'bold'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '30px', border: 'none', color: 'black', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(233,240,253)', outlineWidth: '0', fontSize: 14 }} type="tel"  
                name="mobileValue"
                autoComplete="off"
                value={this.state.mobileValue}
                placeholder="Numero di telefono"
                onChange={this.insertText}
                />
                </div>
                </div>
                </div>
            
            
                <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/terms-and-conditions">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/privacy-policy">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmNumber}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('skip.login')}</span></div>
   
                    </div>
                    </Drawer>
          <Drawer
                    open={this.state.openPrefix}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>

                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.codeVerification}
                        modalElementClass="modalMobileCode">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: '#3815E8', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('confirm.your.number')} </span>
            <br /> <br /><span style={{color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>+{this.state.prefixValue} {this.state.mobileValue}</span>
            </div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '10px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('insert.activation.code')}</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', fontWeight: 'bold', color: 'black', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(240,240,240)', outlineWidth: '0', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="tel" 
                name="activationcode"
                autoComplete="off"
                maxLength={4}
                value={this.state.activationcode}
                placeholder={i18n.t('activation.code')}
                onChange={this.insertText}
                />
               </div>

                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmActivationCode}
            >{i18n.t('confirm')}</p>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default Course
