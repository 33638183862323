import React, { Component } from 'react';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/400-italic.css";// Defaults to weight 400
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700-italic.css"; 
import Drawer from 'react-drag-drawer';
import timeicon from './media/timeicon.png';
import aiicon from './media/aiicon.png';
import tailoredicon from './media/tailoredicon.png';
import arrowcontinue from './media/ai.png';
import checkok from './media/checkok.png';
import checkno from './media/checkno.png';
import teachyailogo from './media/teachy.png';
import creditcard from './media/creditcard.png';
import calendar from './media/calendar.png';
import './Popupstyle.css';
import { BoxLoading, SemipolarLoading, PointSpreadLoading } from 'react-loadingg';
import i18n from "i18next";

export class SubscriptionPlan extends Component {


    constructor(props){
        super(props);
        this.state = { plan: props.plan, isReady: false, showConfirmPlan: false, paymentForm: false, 
          openLoader: true, confirmCancel: false };
    }

    componentDidMount () {
    
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken != null){
            this.getMyInfo(sessiontoken);
               
          return;
        }
       
    }


    getMyInfo = (token) => {
  
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: token
          })
          };
        
          const apiUrl = 'https://teachyai.com/api/client/v1/plans/';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
        
          this.setState({ plan: r.plan, subInfo: r.subinfo, isReady: true, openLoader: false });
        }).catch(e => {
           
        });
    
      }

      cancelPlans = () => {
        this.setState({ showConfirmPlan: false, paymentForm: false, confirmCancel: false });
      }


      selectPlan = (plan) => {
        if(plan == this.state.plan){ return; }

        var planTitle = 'Free';
        var planSubtitle = '0€/'+i18n.t('month');
        if(plan == 1){
            planTitle = 'PREMIUM';
            planSubtitle = '4.99€/'+i18n.t('month');
        }else if(plan == 2){
            planTitle = 'BUSINESS';
            planSubtitle = '9.99€/'+i18n.t('month');
        }
        this.setState({ selectedPlan: plan, selectedPlanTitle: planTitle, selectedPlanSubtitle: planSubtitle, showConfirmPlan: true });
        
      }

      confirmSelection = () => {
        if(this.state.selectedPlan == '0'){
            return;
        }

        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken == null){
            localStorage.removeItem('session_token');
            window.location.reload();
            return;
        }


        this.setState({  openLoader: true });

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              plan: this.state.selectedPlan
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/plans/payment';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
          
            window.location.href = 'https://teachyai.com/api/client/v1/payment/checkout?token='+r.token;
            return;
          }).catch(e => {
            window.location.reload();
          });
        
      }


      cancelSubscription = () => {
        this.setState({ confirmCancel: true });
      }

   
      confirmCancelSub = () => {
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken == null){
            localStorage.removeItem('session_token');
            window.location.reload();
            return;
        }


        this.setState({  openLoader: true, confirmCancel: false });

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/plans/cancel';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
          this.getMyInfo(sessiontoken);
          }).catch(e => {
            window.location.reload();
          });
      }

    render() {
        return (
            <div> 
  <br />
        <div style={{textAlign: 'center'}}><span style={{color: 'black', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('the.course')} </span><span style={{color: '#3815E8', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('tailored')} </span><span style={{color: 'black', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('for.you')} <br/>{i18n.t('thanks.to')} </span><span style={{color: '#3815E8', fontSize: 26, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('ai')}</span></div>
        <br />
        
        {(this.state.isReady && this.state.plan != '0') &&
        <div style={{ display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        flexWrap: 'wrap',
        flexShrink: 1}}>
    
        <div style={{width: '250px', height: '350px', position: 'relative', margin: '15px'}}>
       
        <div style={{width: 250, height: 240, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 30}} />
        <div style={{width: 40, height: 40, left: 20, top: 20, position: 'absolute'}}>
        <img src={teachyailogo} style={{ borderRadius: '10px'}} width="40px" />
         </div>
        <div style={{width: 180, height: 25, left: 20, top: 70, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 24, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>Teachy AI</div>
        <div style={{width: 180, height: 25, left: 20, top: 110, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{this.state.plan == '1' ? 'PREMIUM' : 'BUSINESS'} </div>
        <div style={{ width: 210, left: 20, top: 140, height: '1px', backgroundColor: 'rgb(230,230,230)', position: 'absolute' }}></div>
        <div style={{width: 20, height: 20, left: 20, top: 160, position: 'absolute'}}>
        <img src={creditcard} width="15px" />
        <div style={{width: 180, height: 50, left: 30, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{this.state.plan == '1' ? '4,99€/' : '9,99€/'}{i18n.t('month')}</div> 
        </div>
        <div style={{width: 20, height: 20, left: 20, top: 200, position: 'absolute'}}>
        <img src={calendar} width="15px" />
        <div style={{width: 180, height: 50, left: 30, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('renewal.day')} {this.state.subInfo.next_renew}</div> 
        </div>
       
        <div onClick={() =>this.cancelSubscription()} style={{ width: 180, height: 50, left: 0, top: 250, width: '300px', height: '60px', position: 'absolute', cursor: 'pointer'}}>
        <div style={{width: 250, height: 50, left: 0, }}>
            <div style={{width: 250, height: 50, left: 0, top: 0, position: 'absolute', background: 'rgb(250,250,250)', borderRadius: 30}} />
            <div style={{width: 230, height: 20, left: 10, top: 15, position: 'absolute', textAlign: 'center', color: 'red', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('cancel.subscription')}</div>
        </div>
        </div>
        <div style={{width: 20, height: 40, left: 0, top: 310, position: 'absolute'}}>
        <div style={{width: 210, height: 40, left: 20, top: 0, position: 'absolute', textAlign: 'left', color: 'rgb(100,100,100)', fontSize: 10, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel.subscription.disclaimer')}</div> 
        </div>
        </div>

        
        </div>
    }

        {(this.state.isReady && this.state.plan == '0') &&
      <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>

<div style={{width: '250px', height: '340px', position: 'relative', margin: '15px' }}>
    <div style={{width: 250, height: 340, left: 0, top: 0, position: 'absolute', background: 'rgb(245,245,245)', borderRadius: 30}} />
    <div style={{width: 180, height: 25, left: 20, top: 30, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>Free</div>
    <div style={{width: 180, height: 25, left: 20, top: 60, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>€0/{i18n.t('month')}</div>
    <div style={{width: 20, height: 20, left: 20, top: 120, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('free.plan.1')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 170, position: 'absolute'}}>
    <img src={checkno} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('free.plan.2')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 220, position: 'absolute'}}>
    <img src={checkno} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('free.plan.3')}</div> 
    </div>
    <div onClick={() =>this.selectPlan('0')} style={{ width: 180, height: 50, left: 25, top: 270, width: '300px', height: '60px', position: 'absolute', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: this.state.plan == 0 ? '#3815E855' : '#3815E8', borderRadius: 30}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{this.state.plan == 0 ? i18n.t('plan.already.using') : i18n.t('plan.to.buy')}</div>
    </div>
    </div>
</div>

<div style={{width: '250px', height: '340px', position: 'relative', margin: '15px' }}>
    <div style={{width: 250, height: 340, left: 0, top: 0, position: 'absolute', background: 'rgb(245,245,245)', borderRadius: 30}} />
    <div style={{width: 180, height: 25, left: 20, top: 30, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>PREMIUM</div>
    <div style={{width: 180, height: 25, left: 20, top: 60, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>€4.99/{i18n.t('month')}</div>
    <div style={{width: 20, height: 20, left: 20, top: 120, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('premium.plan.1')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 170, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('premium.plan.2')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 220, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('premium.plan.3')}</div> 
    </div>
    <div onClick={() =>this.selectPlan('1')} style={{ width: 180, height: 50, left: 25, top: 270, width: '300px', height: '60px', position: 'absolute', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: this.state.plan == 1 ? '#3815E855' : '#3815E8', borderRadius: 30}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{this.state.plan == 1 ? i18n.t('plan.already.using') : i18n.t('plan.to.buy')}</div>
    </div>
    </div>
</div>

<div style={{width: '250px', height: '340px', position: 'relative', margin: '15px' }}>
    <div style={{width: 250, height: 340, left: 0, top: 0, position: 'absolute', background: 'rgb(245,245,245)', borderRadius: 30}} />
    <div style={{width: 180, height: 25, left: 20, top: 30, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>BUSINESS</div>
    <div style={{width: 180, height: 25, left: 20, top: 60, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>€9.99/{i18n.t('month')}</div>
    <div style={{width: 20, height: 20, left: 20, top: 120, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('business.plan.1')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 170, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('business.plan.2')}</div> 
    </div>
    <div style={{width: 20, height: 20, left: 20, top: 220, position: 'absolute'}}>
    <img src={checkok} width="15px" />
    <div style={{width: 180, height: 50, left: 25, top: 0, position: 'absolute', textAlign: 'left', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('business.plan.3')}</div> 
    </div>
    <div onClick={() =>this.selectPlan('2')} style={{ width: 180, height: 50, left: 25, top: 270, width: '300px', height: '60px', position: 'absolute', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: this.state.plan == 2 ? '#3815E855' : '#3815E8', borderRadius: 30}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{this.state.plan == 2 ? i18n.t('plan.already.using') : i18n.t('plan.to.buy')}</div>
    </div>
    </div>
</div>

</div>
    }
<br /><br />

            <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#3815E8" />
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showConfirmPlan}
                    onRequestClose={this.cancelPlans}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{this.state.selectedPlanTitle}</span></div>
            <div style={{textAlign: 'center'}}><span style={{color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{this.state.selectedPlanSubtitle}</span></div>
            
            <div style={{ textAlign: 'center', marginTop: '15px', color: 'rgb(198,210,25)', fontSize: '11px', fontWeight: 'bold'}}>
                🔒 {i18n.t('secure.payment')}
                </div>
            <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmSelection}
            >{i18n.t('plan.to.buy')}</p>
                    </div>
                    <div onClick={this.cancelPlans} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel')}</span></div>
   
           
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.confirmCancel}
                    onRequestClose={this.cancelPlans}
                        modalElementClass="modal">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('want.cancel.subscription')}</span></div>
            <div style={{textAlign: 'center', margin: '10px'}}><span style={{color: 'rgb(100,100,100)', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel.subscription.disclaimer')}</span></div>
            
           
            <div  style={{marginTop: '5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'red', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmCancelSub}
            >{i18n.t('cancel.subscription')}</p>
                    </div>
                    <div onClick={this.cancelPlans} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('cancel')}</span></div>
   
           
                    </div>
                    </Drawer>


                  

            </div>
        )
    }
}

export default SubscriptionPlan
