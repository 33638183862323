import React, { Component } from 'react';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/400-italic.css";// Defaults to weight 400
import "@fontsource/plus-jakarta-sans/700.css"; // Specify weight
import "@fontsource/plus-jakarta-sans/700-italic.css"; 
import { BoxLoading, SemipolarLoading, PointSpreadLoading } from 'react-loadingg';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import i18n from "i18next";

export class MyCourses extends Component {


    constructor(props){
        super(props);
        
        this.state = { isLoading: false, isLogged: false, courses: [], activationcode: '', codeVerification: false, confirmMobileNumber: false, prefixDisplay: '🇮🇹 +39', openPrefix: false, mobileValue: '', prefixValue: '39'};
    }

    componentDidMount () {
    
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken != null){
            this.getMyCourses(sessiontoken);
               
          return;
        }
       
    }

    insertText = (e) => {
        var insert = String(e.target.value);   
        var fieldName = e.target.name;  
        this.setState({ [fieldName]: insert});     
    }

    getMyCourses = (token) => {
  
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: token
          })
          };
        
          const apiUrl = 'https://teachyai.com/api/client/v1/course/list';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         
          this.setState({ courses: r.courses, isLogged: true });
        }).catch(e => {
            this.setState({ confirmMobileNumber: true, isLogged: false });
        });
    
      }

      toggle = () => {
        this.props.close();
    }

    selectPrefix = (e) => {
        
        this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id, openPrefix: false });
    
    }

    showPrefixSelection = () => {
        this.setState({ openPrefix: true });
    }

    closeAnimation = (e) => {
        this.setState({ animationShake1: '', animationShake2: ''}); 
        clearInterval(this.intervalID);
    }

    confirmNumber = () => {
        if(!(this.state.mobileValue.length>8 && this.state.mobileValue.length<12)){
            return;
        }

        if(!this.state.ck2){
            this.setState({ animationShake2: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }

        if(!this.state.ck1){
            this.setState({ animationShake1: 'animationShake'}); 
            this.intervalID = setInterval(this.closeAnimation, 300);
            return;
        }
      
        if(this.state.isLoading){
            return;
        }
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              mobile: this.state.mobileValue,
              prefix: this.state.prefixValue
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/login';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
           if(r.status == 'code'){
            this.setState({ codeVerification: true, confirmMobileNumber: false, isLoading: false });
           }else{
            localStorage.removeItem('session_token');
              window.location.reload();
           }
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    confirmActivationCode = () => {
        if(this.state.activationcode.length!=4){
            return;
        }

        if(this.state.isLoading){
            return;
        }

        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
      
        if(sessiontoken === null){
            window.location.reload();
        }

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.activationcode,
            })
            };
          
            const apiUrl = 'https://teachyai.com/api/client/v1/hello/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
           if(r.status == 'ok'){
            window.location.reload();
            
           }else{
            this.setState({ activationcode: '' });
           }

           this.setState({ isLoading: false });
            
          }).catch(e => {
            localStorage.removeItem('session_token');
            window.location.reload();
          });
    }

    showCreateCourse = () => {
        this.props.create();
    }
    

    onItemClick = (code) => {

        var newLocation = "/"+code;
        window.location.href = newLocation;
        return;


    }

    checkboxAction = (e) => {
        var checkBox = e.target.name;
        if(checkBox === "ck1"){
            if(this.state.ck1){
                this.setState({ ck1: false}); 
            }else{
                this.setState({ ck1: true}); 
            }
        }else if(checkBox === "ck2"){
            if(this.state.ck2){
                this.setState({ ck2: false}); 
            }else{
                this.setState({ ck2: true}); 
            }
        }
        
    }
  
    render() {
        return (
        <div> 
            {this.state.isLogged &&
            <div>
          <div style={{textAlign: 'center', margin: '15px'}}>
            
            <span style={{color: 'black', fontSize: 28, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}></span><span style={{color: '#3815E8', fontSize: 28, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('my.courses')}</span></div>
     
            <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1,
    margin: '20px'}}>
<div onClick={this.showCreateCourse} style={{width: '300px', height: '60px', position: 'relative', cursor: 'pointer'}}>
    <div style={{width: 250, height: 50, left: 50, position: 'absolute', }}>
        <div style={{width: 200, height: 50, left: 0, top: 0, position: 'absolute', background: '#3815E8', borderRadius: 60.80}} />
        <div style={{width: 150, height: 20, left: 25, top: 15, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '800', wordWrap: 'break-word'}}>{i18n.t('create.course')}</div>
    </div>
    </div>
    </div>
    </div>}

            <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>

                {
           this.state.courses.map((course, i) =>
           <div key={i} style={{margin: '10px', width: '250px', backgroundColor: 'rgb(250,250,250)', borderRadius: '10px', border: '1px rgba(0, 0, 0, 0.10) solid', color: 'black', textAlign: 'center', 
           justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
           onClick={() => this.onItemClick(course.course_code)}  >
               <div style={{ width: '300px', textAlign: 'center', 
           justifyContent: 'center', alignItems: 'center'}}>
               <p style={{color: 'black', fontFamily: 'Plus Jakarta Sans', fontWeight: '700', fontSize: '16px' }}>{course.course_subject}</p>
               <p style={{marginTop: '-10px', fontSize: '12px', fontFamily: 'Plus Jakarta Sans'}}>{course.course_level == "1" ? i18n.t('basic.level') : course.course_level == "2" ? i18n.t('medium.level') : i18n.t('advanced.level')}   |   {course.course_lenght == "1" ? "3" : course.course_lenght == "2" ? "5" : "8"} {i18n.t('chapters')}</p>
               <p style={{color: 'black', fontFamily: 'Plus Jakarta Sans', fontWeight: '400', fontSize: '12px' }}>{course.display_text}</p>
               </div>                                    
               </div>
                )
                }

            

                 
                   
                    </div>
  
    

   <br />
    
  

          <Drawer
                    open={this.state.confirmMobileNumber}
                        onRequestClose={this.toggle}
                        modalElementClass="modalMobileLogin">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'black', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>Accedi ai tuoi </span><span style={{color: '#3815E8', fontSize: 22, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>corsi</span></div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '15px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>Conferma il tuo numero di telefono per accedere ai <span style={{color: '#3815E8', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>tuoi corsi</span> e consultarli da questo dispositivo</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#3815E8', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'bold'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '30px', border: 'none', color: 'black', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(233,240,253)', outlineWidth: '0', fontSize: 14 }} type="tel"  
                name="mobileValue"
                autoComplete="off"
                value={this.state.mobileValue}
                placeholder="Numero di telefono"
                onChange={this.insertText}
                />
                </div>
                </div>
                </div>
            
            
                <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/terms-and-conditions">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.teachyai.com/agreements/privacy-policy">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmNumber}
            >{i18n.t('confirm')}</p>
                    </div>
                    </div>
                    </Drawer>
          <Drawer
                    open={this.state.openPrefix}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>

                    </div>
                    </Drawer>


                    <Drawer
                    open={this.state.codeVerification}
                        modalElementClass="modalMobileCode">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: '#3815E8', fontSize: 20, fontFamily: 'Plus Jakarta Sans', fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('confirm.your.number')} </span>
            <br /> <br /><span style={{color: 'black', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>+{this.state.prefixValue} {this.state.mobileValue}</span>
            </div>
            <div style={{textAlign: 'center', maxWidth: '500px', margin: '10px'}}><span style={{color: 'black', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('insert.activation.code')}</span></div>
       
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', fontWeight: 'bold', color: 'black', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(240,240,240)', outlineWidth: '0', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="tel" 
                name="activationcode"
                autoComplete="off"
                maxLength={4}
                value={this.state.activationcode}
                placeholder={i18n.t('activation.code')}
                onChange={this.insertText}
                />
               </div>

                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#3815E8', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmActivationCode}
            >{i18n.t('confirm')}</p>
                    </div>
                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default MyCourses
